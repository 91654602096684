import { SelectionChangedEvent } from "ag-grid-community";
import { SupplementaryInvoiceEntity } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceEntity";
import { INITIAL_SUPPLEMENTARY_INVOICE_COL_DEF, transferRowData } from "presentation/constant/SupplementaryInvoice/SupplementaryInvoiceColumnDefinition";
import { useSupplementaryInvoiceVM } from "presentation/hook/SupplementaryInvoice/useSupplementaryInvoiceVM";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
const SupplementaryInvoiceTablePanel:React.FC = () => {
    const gridRef: any = useRef(null);
    const [supplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const supplementaryInvoiceVM = useSupplementaryInvoiceVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_SUPPLEMENTARY_INVOICE_COL_DEF.slice());
        
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        supplementaryInvoiceVM.updateSelectedCharges(selectedRows);
      }, [supplementaryInvoiceVM]);

      const handleRowDoubleClick = useCallback((hdr: SupplementaryInvoiceEntity) => {
        supplementaryInvoiceVM.onRowDoubleClick(hdr);
    }, [supplementaryInvoiceVM])
      
    const memoSupplementaryInvoiceTable = useMemo(() => {

        return (
        <HPHTable
            id='supplementary-invoice-table'
            isNewColumnSetting={true}
            columns={INITIAL_SUPPLEMENTARY_INVOICE_COL_DEF.slice()}
            data={transferRowData(supplementaryInvoiceState.SupplementaryInvoiceList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, hdr: SupplementaryInvoiceEntity) => handleRowDoubleClick(hdr)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
        />
        );
    },[handleRowDoubleClick, handleSelectionChange, supplementaryInvoiceState.SupplementaryInvoiceList])

    
    return <>
    {(supplementaryInvoiceState.isLoading)&& <Loader Indicator="Spinner" size="Medium" /> }
            <TableWrapper>
                {memoSupplementaryInvoiceTable}
            </TableWrapper>
           </>;
}
export default memo(SupplementaryInvoiceTablePanel);