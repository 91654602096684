import { MessageConstant } from "presentation/constant/MessageConstant";
import { SupplementaryInvoiceConstant } from "presentation/constant/SupplementaryInvoice/SupplementaryInvoiceConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useSupplementaryInvoiceVM } from "presentation/hook/SupplementaryInvoice/useSupplementaryInvoiceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const SupplementaryInvoiceTitleBar:React.FC = () => {
    const SUPPLEMENTARY_INVOICE_CONSTANT = SupplementaryInvoiceConstant.Title;
    const [supplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const supplementaryInvoiceVM = useSupplementaryInvoiceVM();
    const messageBarVM = useMessageBarVM();
    const {searchCriteria} = supplementaryInvoiceState;
    const {selectRows} = supplementaryInvoiceState.supplementaryInvoiceState;
    const [anaInfoState] = useANAInfoTracked();
    const {allowUpdate, allowDelete} = anaInfoState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);

    const handleSearch = useCallback(() => {
        supplementaryInvoiceVM.onSearchClick();
    }, [supplementaryInvoiceVM]);

    const handleConfirm = useCallback(() => {
        if(selectRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return false;
        }
        if(!selectRows.every(entity => entity.hdrState === "NIL")){
            messageBarVM.showWarining("The selected supplementary invoice is not in 'NIL' state.");
            return false;
        }
        supplementaryInvoiceVM.onShowLoading();
        supplementaryInvoiceVM.onConfirm(selectRows).then(async (data) =>{
            if(data.success){
                messageBarVM.showSuccess("The record(s) is confirmed successfully.");
                await supplementaryInvoiceVM.onSearch(searchCriteria).then((data) =>{
                    supplementaryInvoiceVM.onHideLoading();
                }).catch(error => {            
                    supplementaryInvoiceVM.onHideLoading();
                });
            }else{
                messageBarVM.showError(data.data??'')
            }
        }).catch(error => {            
            supplementaryInvoiceVM.onHideLoading();
        }).finally(() => {
            supplementaryInvoiceVM.onHideLoading();
        })
    }, [messageBarVM, searchCriteria, selectRows, supplementaryInvoiceVM]);

    const handleUnConfirm = useCallback(() => {
        if(selectRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return false;
        }
        if(!selectRows.every(entity => entity.hdrState === "CFM")){
            messageBarVM.showWarining("The selected supplementary invoice is not in 'CFM' state.");
            return false;
        }
        supplementaryInvoiceVM.onShowLoading();
        supplementaryInvoiceVM.onUnConfirm(selectRows).then(async (data) =>{
            if(data.success){
                messageBarVM.showSuccess("The record(s) is unConfirmed successfully.");
                await supplementaryInvoiceVM.onSearch(searchCriteria).then((data) =>{
                    supplementaryInvoiceVM.onHideLoading();
                }).catch(error => {            
                    supplementaryInvoiceVM.onHideLoading();
                });
            }else{
                messageBarVM.showError(data.data??'')
            }
        }).catch(error => {            
            supplementaryInvoiceVM.onHideLoading();
        }).finally(() => {
            supplementaryInvoiceVM.onHideLoading();
        })
    }, [messageBarVM, searchCriteria, selectRows, supplementaryInvoiceVM]);

    const handleDelete = useCallback(async () => {
        if(selectRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return false;
        }
        if(!selectRows.every(entity => entity.hdrState === "NIL")){
            messageBarVM.showWarining("The selected supplementary invoice is not in 'NIL' state.");
            return false;
        }

        setIsShowDelete(false);
        supplementaryInvoiceVM.onShowLoading();
        await supplementaryInvoiceVM.onDelete(selectRows).then(async (data) =>{
            if(data.success){
                messageBarVM.showSuccess("The record(s) is deleted successfully.");
                await supplementaryInvoiceVM.onSearch(searchCriteria).then((data) =>{
                    supplementaryInvoiceVM.onHideLoading();
                }).catch(error => {            
                    supplementaryInvoiceVM.onHideLoading();
                });
            }else{
                messageBarVM.showError(data.data??'')
            }
        }).catch(error => {            
            supplementaryInvoiceVM.onHideLoading();
        }).finally(() => {
            supplementaryInvoiceVM.onHideLoading();
        })
    }, [messageBarVM, searchCriteria, selectRows, supplementaryInvoiceVM]);

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);    

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const isDisable = () => {
        if(selectRows.length > 0 && selectRows.every(entity => entity.hdrState === "NIL")) return false;
        return true;
    }

    const isUnconfirmDisable = () => {
        if(selectRows.length > 0 && selectRows.every(entity => entity.hdrState === "CFM")) return false;
        return true;
    }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{SUPPLEMENTARY_INVOICE_CONSTANT.TITLE}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            {(allowDelete) && <><div className="add-seperator"/>
            <HPHButton label={SUPPLEMENTARY_INVOICE_CONSTANT.DELETE} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleDeleteClick} /></>}
            {(allowUpdate) && <><div className="add-seperator"/>
            <HPHButton label={SUPPLEMENTARY_INVOICE_CONSTANT.UNCONFIRM} disabled={isUnconfirmDisable()} size={'Small'} theme={'Secondary'} onClick={handleUnConfirm} />
            <HPHButton label={SUPPLEMENTARY_INVOICE_CONSTANT.CONFIRM} disabled={isDisable()} size={'Small'} theme={'Primary'} onClick={handleConfirm} /></>}

            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${SUPPLEMENTARY_INVOICE_CONSTANT.TITLE}`} 
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(SupplementaryInvoiceTitleBar);