import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { SupplementaryInvoiceProvider } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { SupplementaryInvoiceMDView } from "presentation/view/section/SupplementaryInvoice/SupplementaryInvoiceMDView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const SupplementaryInvoiceContMain = () => {
    return <ANAInfoWrapper permission={Permission.SUPPLEMENTARY_INVOICE}>
        <MessageBarWrapper>
            <SupplementaryInvoiceProvider>
                <GridStyles/>
                <SupplementaryInvoiceMDView/>
            </SupplementaryInvoiceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default SupplementaryInvoiceContMain;