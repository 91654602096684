import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import SupplementaryInvoiceDetailContMain from "presentation/view/container/SupplementaryInvoice/SupplementaryInvoiceDetailContMain";
import React from "react";
import SupplementaryInvoiceMaintenance from "./SupplementaryInvoiceMaintenance";

export const SupplementaryInvoiceMDView: React.FC = () => {
     const [supplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const { isShowDetail } = supplementaryInvoiceState;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <SupplementaryInvoiceMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><SupplementaryInvoiceDetailContMain/> </div>
    } 

    </>
}
