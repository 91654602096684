import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { SupplementaryInvoiceRepoImpl } from "domain/repository/SupplementaryInvoice/SupplementaryInvoiceRepoImpl";
import { UserRepoImpl } from "domain/repository/User/UserRepoImpl";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { SupplementaryInvoiceVM } from "presentation/viewModel/SupplementaryInvoice/SupplementaryInvoiceVM";
import { useMemo } from "react";

export const useSupplementaryInvoiceVM = () => {
    const [, setSupplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const supplementaryInvoiceVM = useMemo(() =>
        SupplementaryInvoiceVM({
            dispatch: [setSupplementaryInvoiceState],
            supplementaryInvoiceRepo: SupplementaryInvoiceRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            userRepo: UserRepoImpl(),
        }), [setSupplementaryInvoiceState])

    return supplementaryInvoiceVM
}

